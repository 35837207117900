import React, { useEffect } from "react";
import "./style.css";
import logo from "../../assets/images/gigmi-logo.svg";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Title from "../../components/DepositInstructions/Title";
import Paragraph from "../../components/DepositInstructions/Paragraph";
import FreelancerFeesTable from "../../components/FeeAgreement/FreelancersFeeTable";

const FeeAgreement = () => {
  const param = useParams();
  const { lang } = param;

  useEffect(() => {
    const storedLang = localStorage.getItem("lang");
  
    if (lang === "es" && storedLang !== "true") {
      localStorage.setItem("lang", "true");
      window.location.reload(); 
    } else if (lang !== "es" && storedLang === "true") {
      localStorage.removeItem("lang");
      window.location.reload(); 
    }
  }, [lang]); 
  return (
    <div style={{ backgroundColor: "#F77E0B" }}>
      <div
        style={{ backgroundColor: "#F77E0B", height: "100%" }}
        className="container pt-5"
      >
        <div className=" top-bar w-100">
          <div className="d-flex justify-content-center w-100">
            <img src={logo} alt="" />
          </div>
        </div>

        <div
          className="shadow  p-5 mt-3 "
          style={{
            paddingTop: "4rem",
            overflowX: "hidden",
            borderRadius: "20px",
            backgroundColor: "white",
          }}
        >
          <div className="row">
            <div className="col-sm-12">
              <div>
                <p>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      <b>
                        <FormattedMessage id="feeAgreement" />
                      </b>
                    </span>
                  </div>
                </p>
                <Paragraph id="fp1"></Paragraph>
                <p></p>
                <Paragraph id="fp2"></Paragraph>
                <p></p>

                <Title id="fh1"></Title>
                <Paragraph id="fl1"></Paragraph>
                <p></p>
                <Title id="fh2"></Title>
                <p></p>
                <Paragraph id="fl2"></Paragraph>

                <p></p>
                <div className="d-flex ms-2 ">
                  <Title id="fh3"></Title>
                </div>
                <p></p>
                <Paragraph id="fl3"></Paragraph>

                <p></p>
                <FreelancerFeesTable></FreelancerFeesTable>

                <p></p>
                <Title id="fh4"></Title>
                <p></p>
                <Paragraph id="fl4"></Paragraph>

                <p></p>
                <Title id="fh5"></Title>
                <p></p>
                <Paragraph id="fl5"></Paragraph>

                <p></p>
                <Title id="fh6"></Title>
                <p></p>
                <Paragraph id="fl6"></Paragraph>
                <p></p>
                <Title id="fh7"></Title>
                <p></p>
                <Paragraph id="fl7"></Paragraph>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeAgreement;

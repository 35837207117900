import React, { useRef, useEffect } from "react";
import "./style.css";
import logo from "../../assets/images/gigmi-logo.svg";
import { useParams } from "react-router-dom";

const PrivacyPolicy = () => {
  const param = useParams();

  const deleteAccountRef = useRef(null);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#delete-account" && deleteAccountRef.current) {
      deleteAccountRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const { lang } = param;
  return (
    <div style={{ backgroundColor: "#F77E0B" }}>
      <div
        style={{ backgroundColor: "#F77E0B", height: "100%" }}
        className="container pt-5"
      >
        <div className=" top-bar w-100">
          <div className="d-flex justify-content-center w-100">
            <img src={logo} alt="" />
          </div>
        </div>

        <div
          className="shadow  p-5 mt-3 "
          style={{
            paddingTop: "4rem",
            overflowX: "hidden",
            borderRadius: "20px",
            backgroundColor: "white",
          }}
        >
          <div className="row">
            <div className="col-sm-12">
              <div>
                <p>
                  <div style={{ width: "100%" }}>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      <b>
                        {lang === "en"
                          ? "PRIVACY POLICY"
                          : "Política de Privacidad"}
                      </b>
                    </span>
                  </div>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                      wordWrap: "break-word",
                    }}
                  >
                    {lang === "en"
                      ? `This privacy policy sets out how GigMi LLC (the “Company”) uses and protects any information that you give the Company when you use this website or the GigMi application, including all Company websites, features, applications, widgets, or online services owned or controlled by the Company. It also includes any information the Company collects offline in connection with the Service. This privacy policy is meant to help you understand what information we collect, why we collect it, and how you can update or delete your information. The Company provides a variety of online services to assist individuals in locating and hiring skilled laborers, for skilled laborers to locate and accept jobs, and related media and services (collectively, the “Service”). This privacy policy does not describe the collection, use, or disclosure of information through means other than this website or any other third party. For information on a third party’s use, collection, or disclosure practices, please review the privacy policy on the applicable third party’s website. The Company is not responsible for the websites, content, or privacy practices of any third party.`
                      : `Esta política de privacidad establece cómo GigMi LLC (la “Compañía”) usa y protege cualquier información que usted le proporcione a la Compañía cuando usa este sitio web o la aplicación GigMi, incluidos todos los sitios web, funciones, aplicaciones, widgets o servicios en línea de la Compañía que son propiedad de la Compañía o están controlados por ella. También incluye cualquier información que la Compañía recopile fuera de línea en relación con el Servicio. Esta política de privacidad tiene como objetivo ayudarlo a comprender qué información recopilamos, por qué la recopilamos y cómo puede actualizar o eliminar su información. La Compañía proporciona una variedad de servicios en línea para ayudar a las personas a localizar y contratar trabajadores calificados, para que los trabajadores calificados encuentren y acepten trabajos, y medios y servicios relacionados (colectivamente, el “Servicio”). Esta política de privacidad no describe la recopilación, el uso o la divulgación de información a través de medios distintos a este sitio web o cualquier otro tercero. Para obtener información sobre las prácticas de uso, recopilación o divulgación de un tercero, revise la política de privacidad en el sitio web del tercero correspondiente. La Compañía no es responsable de los sitios web, el contenido o las prácticas de privacidad de ningún tercero.`}
                  </span>
                </p>

                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `The Company may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy was last updated on November 1, 2024.`
                      : `La Compañía puede cambiar esta política de vez en cuando actualizando esta página. Debe consultar esta página de vez en cuando para asegurarse de que está conforme con los cambios. Esta política se actualizó por última vez el 1 de noviembre de 2024.`}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? "OUR COMMITMENT TO INDIVIDUAL PRIVACY"
                        : "Nuestro Compromiso con la Privacidad Individual"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `The Company is committed to protecting the privacy and accuracy of your information. We collect Usage Data (as described below) and may collect zip codes and other information in connection with your use of the Company website. We collect personally identifiable information only when you provide it, and we collect the least amount of personally identifiable information required to provide you with requested media and services. The types of personally identifiable information collected will depend on the nature of the submission or the features of the website used, but could include your name, address, phone number, and/or email address. If you use the website or services to make or receive payments, we will also collect certain payment information, such as credit card or other financial account information, and billing address. The Company does not request and we ask that you not send us any sensitive information (e.g., government-issued identification numbers, racial or ethnic information, political opinions, religious affiliations, health, biometrics or genetic characteristics).`
                      : `La Compañía se compromete a proteger la privacidad y la precisión de su información. Recopilamos Datos de uso (como se describe a continuación) y podemos recopilar códigos postales y otra información en relación con su uso del sitio web de la Compañía. Recopilamos información de identificación personal solo cuando usted la proporciona, y recopilamos la menor cantidad de información de identificación personal necesaria para brindarle los medios y servicios solicitados. Los tipos de información de identificación personal recopilada dependerán de la naturaleza del envío o las características del sitio web utilizado, pero podrían incluir su nombre, dirección, número de teléfono y/o dirección de correo electrónico. Si utiliza el sitio web o los servicios para realizar o recibir pagos, también recopilaremos cierta información de pago, como información de tarjeta de crédito u otra información de cuenta financiera y dirección de facturación. La Compañía no solicita y le pedimos que no nos envíe ninguna información confidencial (por ejemplo, números de identificación emitidos por el gobierno, información racial o étnica, opiniones políticas, afiliaciones religiosas, salud, biometría o características genéticas).`}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? "CHILDREN’S ONLINE PRIVACY PROTECTION ACT"
                        : "Ley de Protección de la Privacidad en Línea de los Niños"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `We do not knowingly collect Personal Data from children under the age of thirteen. If you are under the age of thirteen, please do not submit any personal information or data through this website. We encourage parents and legal guardians to monitor their children’s internet usage and to help enforce our Privacy Policy by instructing their children never to provide personal information or data through the website without their permission. If you have any reason to believe that a child under the age of thirteen has provided personal information or data to the Company through the website, please contact us and we will endeavor to delete that information from our databases.`
                      : `No recopilamos deliberadamente datos personales de niños menores de trece años. Si tiene menos de trece años, no envíe ninguna información o dato personal a través de este sitio web. Animamos a los padres y tutores legales a que supervisen el uso de Internet de sus hijos y a que ayuden a hacer cumplir nuestra Política de privacidad instruyendo a sus hijos a que nunca proporcionen información o datos personales a través del sitio web sin su permiso. Si tiene alguna razón para creer que un niño menor de trece años ha proporcionado información o datos personales a la Compañía a través del sitio web, póngase en contacto con nosotros y haremos todo lo posible por eliminar esa información de nuestras bases de datos.`}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en" ? "WHAT WE COLLECT" : "Lo que Recopilamos"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `We collect a variety of information that you provide directly to us. In general, we collect information from you through:`
                      : `Recopilamos una variedad de información que usted nos proporciona directamente. En general, recopilamos información suya a través de:`}
                  </span>
                </p>
                <ul>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Using any of our media or services, including registering for a user account through our websites`
                          : "Utilizando cualquiera de nuestros medios o servicios, incluido el registro de una cuenta de usuario a través de nuestros sitios web"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? "Signing up for e-mail updates"
                          : "Inscribirse para recibir actualizaciones por correo electrónico"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Uploading or posting to public forums associated with the Company or through social media sites`
                          : "Cargar o publicar en foros públicos asociados con la Compañía o a través de sitios de redes sociales"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? "Submitting requests or questions to us via forms or e-mail"
                          : "Enviar solicitudes o preguntas a través de formularios o correo electrónico"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? "Requesting customer support and technical assistance"
                          : "Solicitar soporte al cliente y asistencia técnica"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? "Your geographic location directly through your devices by observing your actions through the Company services and website."
                          : "Su ubicación geográfica directamente a través de sus dispositivos mediante la observación de sus acciones a través de los servicios y el sitio web de la Compañía."}
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? "We may collect the following information:"
                      : "Podemos recopilar la siguiente información:"}
                  </span>
                </p>
                <ul>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? "Name, address, telephone number, and e-mail address"
                          : "Nombre, dirección, número de teléfono y dirección de correo electrónico"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? "Log-in credentials, if you register as a user through our websites"
                          : "Credenciales de inicio de sesión, si se registra como usuario a través de nuestros sitios web"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Information about your customer service and maintenance interactions with us`
                          : `Información sobre sus interacciones de servicio al cliente y mantenimiento con nosotros`}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Information you provide to us in connection with any orders and transactions you carry out through the Company website and services`
                          : "Información que usted nos proporciona en relación con cualquier pedido y transacción que realice a través del sitio web y los servicios de la Compañía"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `User-generated content you upload or post through our website or services, including any community forums, blogs, and other interactive features on the website`
                          : "Contenido generado por el usuario que usted carga o publica a través de nuestro sitio web o servicios, incluidos foros comunitarios, blogs y otras funciones interactivas en el sitio web"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Information from third parties about you in connection with any affiliate programs we may offer`
                          : "Información de terceros sobre usted en relación con cualquier programa de afiliados que podamos ofrecer"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Information to verify your identity and to detect fraud, identity theft, or other misuse of your account through facial recognition and other technologies. This might be a photograph of your face, a selfie, or data about your interactions with the website and services. We may request documents to verify this information, such as a copy of your government-issued ID. From time to time, the Company may require you to take and submit another or updated image of your face for comparison to your government-issued ID. The biometric identifiers or information collected are used only to verify your identity and platform security and use integrity purposes.`
                          : "Información para verificar su identidad y detectar fraudes, robo de identidad u otro uso indebido de su cuenta a través del reconocimiento facial y otras tecnologías. Puede ser una fotografía de su rostro, una selfie o datos sobre sus interacciones con el sitio web y los servicios. Podemos solicitarle documentos para verificar esta información, como una copia de su identificación emitida por el gobierno. De vez en cuando, la Compañía puede solicitarle que tome y envíe otra imagen actualizada de su rostro para compararla con su identificación emitida por el gobierno. Los identificadores biométricos o la información recopilada se utilizan únicamente para verificar su identidad y para fines de seguridad e integridad de uso de la plataforma."}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Location information and activity through your device in connection with your use of the Company services and websites.`
                          : "Información de ubicación y actividad a través de su dispositivo en relación con su uso de los servicios y sitios web de la Compañía."}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Any other information you choose to directly provide to us in connection with your use of our website, content, or services`
                          : "Cualquier otra información que usted elija proporcionarnos directamente en relación con su uso de nuestro sitio web, contenido o servicios."}
                      </span>
                    </p>
                  </li>
                </ul>
                <li>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? "You do not have a statutory obligation to provide us with any information, but you may have a contractual obligation to do so, and if we do not receive certain information from you, then we will not be able to provide our services to you. If you have any questions regarding whether provision of information is mandatory and the consequences for withholding such information, please contact us using the contact information below."
                      : "No tiene una obligación legal de proporcionarnos ninguna información, pero puede tener una obligación contractual de hacerlo, y si no recibimos cierta información de usted, entonces no podremos brindarle nuestros servicios. Si tiene alguna pregunta sobre si la provisión de información es obligatoria y las consecuencias de retener dicha información, por favor, contáctenos utilizando la información de contacto a continuación."}
                    <p></p>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? "You may have the opportunity to create a profile, which consists of information about you, and may include personal information, photographs, examples of your work, information on work previously performed via the website or service and outside the service, skills, tests taken, test scores, hourly pay rates and earnings information, feedback/rating information and other information, including your username (“Profile”). The information in your Profile may be visible to all Company Users and the general public subject to the privacy choices you make within your Profile. You may edit certain information in your Profile via your account and may choose to limit who is able to view certain content you post to your Profile. Clients and agencies of associated individual users or companies may also have the opportunity to create an organization Profile. If, in any case, you believe that an unauthorized profile has been created about you, you can request for it to be removed by contacting us at support@gigmiapp.com."
                      : "Puede tener la oportunidad de crear un perfil, que consiste en información sobre usted y puede incluir información personal, fotografías, ejemplos de su trabajo, información sobre trabajos realizados previamente a través del sitio web o servicio y fuera del servicio, habilidades, pruebas realizadas, puntuaciones de pruebas, tasas de pago por hora e información de ganancias, información de retroalimentación/calificación y otra información, incluyendo su nombre de usuario (Perfil). La información en su Perfil puede ser visible para todos los Usuarios de la Compañía y el público en general, sujeto a las opciones de privacidad que elija dentro de su Perfil. Puede editar cierta información en su Perfil a través de su cuenta y puede optar por limitar quién puede ver cierto contenido que publique en su Perfil. Los clientes y agencias de usuarios individuales o empresas asociadas también pueden tener la oportunidad de crear un Perfil de organización. Si, en cualquier caso, cree que se ha creado un perfil no autorizado sobre usted, puede solicitar que se elimine poniéndose en contacto con nosotros en support@gigmiapp.com."}
                    <p></p>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? "You must provide valid financial and payment information if you wish to use the services and the website. The Company uses third party payment processors to process payments made to us. In connection with the processing of such payments, we do not retain any personally identifiable information or any financial information such as credit card numbers. Except for the name associated with your financial information about the transaction (e.g., time of transaction, amount), payment and financial institution information is collected directly by a third-party payment vendor and is not received or stored by us. The Company uses Aero Payments, Inc. as our third-party partner for the procession of payments to you. All information you provide in connection with the same is subject to Aero Payments’ terms of service and privacy policy. The Company is not responsible for the websites, content, or privacy practices of any third party."
                      : "Debe proporcionar información financiera y de pago válida si desea utilizar los servicios y el sitio web. La Compañía utiliza procesadores de pago de terceros para procesar los pagos que nos realiza. En relación con el procesamiento de dichos pagos, no retenemos ninguna información de identificación personal ni información financiera, como números de tarjetas de crédito. A excepción del nombre asociado con su información financiera sobre la transacción (por ejemplo, hora de la transacción, monto), la información de pago e institución financiera es recopilada directamente por un proveedor de pagos externo y no es recibida ni almacenada por nosotros. La Compañía utiliza a Aero Payments, Inc. como nuestro socio externo para el procesamiento de los pagos que le realicemos. Toda la información que proporcione en relación con el mismo está sujeta a los términos de servicio y la política de privacidad de Aero Payments. La Compañía no es responsable de los sitios web, el contenido o las prácticas de privacidad de ningún tercero."}
                    <p></p>
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? "You must provide valid information related to the name, address, and tax identification numbers of an individual receiving income if you wish to use the services and the website. The information taken from a W-9 form is used to generate forms required for income tax filing purposes. The Company uses a third party processor to process all such information. In connection with the processing of such information, we do not retain any personally identifiable information, such as tax identification numbers. Except for the name associated with your account and information related to your transaction, all information is collected directly by a third-party vendor and is not received or stored by the Company. We use Everee, Inc. as our third-party vendor for the collection, processing, and issuance of all tax documentation. All information you provide in connection with the same is subject to Everee’s terms of service and privacy policy. The Company is not responsible for the websites, content, or privacy practices of any third party."
                      : "Debe proporcionar información válida relacionada con el nombre, la dirección y los números de identificación fiscal de una persona que recibe ingresos si desea utilizar los servicios y el sitio web. La información extraída de un formulario W-9 se utiliza para generar los formularios necesarios para la presentación de la declaración de impuestos sobre la renta. La Compañía utiliza un procesador externo para procesar toda esa información. En relación con el procesamiento de dicha información, no conservamos ninguna información de identificación personal, como números de identificación fiscal. A excepción del nombre asociado con su cuenta y la información relacionada con su transacción, toda la información es recopilada directamente por un proveedor externo y no es recibida ni almacenada por la Compañía. Utilizamos a Everee, Inc. como nuestro proveedor externo para la recopilación, el procesamiento y la emisión de toda la documentación fiscal. Toda la información que proporcione en relación con la misma está sujeta a los términos de servicio y la política de privacidad de Everee. La Compañía no es responsable de los sitios web, el contenido o las prácticas de privacidad de ningún tercero."}

                    <p></p>
                  </span>
                </li>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "normal",
                    fontSize: "large",
                  }}
                >
                  <b>
                    {lang === "en"
                      ? "INFORMATION WE COLLECT THROUGH AUTOMATED MEANS"
                      : "INFORMACIÓN QUE RECOPILAMOS A TRAVÉS DE MEDIOS AUTOMATIZADOS"}
                  </b>
                </span>
                <p></p>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "normal",
                    fontSize: "medium",
                  }}
                >
                  <i>
                    <b>
                      {lang === "en"
                        ? "Cookies, Web Beacons"
                        : "Cookies, Web Beacons"}
                    </b>
                  </i>
                </span>
                <p></p>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "normal",
                    fontSize: "medium",
                  }}
                >
                  {lang === "en"
                    ? `A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. Web beacons are electronic images embedded into a web page, which may be placed by us or a third party tracking utility partner) can tell us what advertising you have seen on the website, and allows us to offer you a more diverse selection of advertising information and may also be used together with cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs. A cookie or web beacon in no way gives us access to your computer or any information about you, other than the data you choose to share with us. Web beacons may also be included in emails sent by the website to determine when and if such emails are opened or forwarded.`
                    : `Una cookie es un pequeño archivo que solicita permiso para ser colocado en el disco duro de su computadora. Una vez que acepta, el archivo se agrega y la cookie ayuda a analizar el tráfico web o le permite saber cuándo visita un sitio en particular. Las cookies permiten que las aplicaciones web le respondan como individuo. La aplicación web puede adaptar sus operaciones a sus necesidades, gustos y disgustos al recopilar y recordar información sobre sus preferencias. Las balizas web son imágenes electrónicas incrustadas en una página web, que podemos colocar nosotros o un socio de utilidad de seguimiento externo) pueden decirnos qué publicidad ha visto en el sitio web y nos permiten ofrecerle una selección más diversa de información publicitaria y también pueden usarse junto con las cookies para identificar qué páginas se están utilizando. Esto nos ayuda a analizar datos sobre el tráfico de la página web y mejorar nuestro sitio web para adaptarlo a las necesidades del cliente. Una cookie o baliza web de ninguna manera nos da acceso a su computadora ni a ninguna información sobre usted, aparte de los datos que elija compartir con nosotros. También se pueden incluir balizas web en los correos electrónicos enviados por el sitio web para determinar cuándo y si dichos correos electrónicos se abren o reenvían.`}
                </span>
                <p></p>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "normal",
                    fontSize: "medium",
                  }}
                >
                  {lang === "en"
                    ? ` You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.`
                    : "Puede elegir aceptar o rechazar las cookies. La mayoría de los navegadores web aceptan automáticamente las cookies, pero normalmente puede modificar la configuración de su navegador para rechazarlas si lo prefiere. Esto puede impedirle aprovechar al máximo el sitio web."}
                </span>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <i>
                      <b>
                        {lang === "en"
                          ? `Analytics Information`
                          : "Información analítica."}
                      </b>
                    </i>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `When you use our services, we and our service providers (which are third-party companies that work on our behalf) may use cookies, and similar technologies to track, collect and aggregate certain information including, among other things, which pages of our websites were visited, the order in which they were visited, when they were visited, the URLs from which you linked to our websites, and which hyperlinks were “clicked.” Collecting such information may involve logging the IP address, operating system and browser software used by each user of the website. Through such information, we may be able to determine from an IP address a user’s Internet Service Provider and the general geographic location (city/state) of his or her point of connectivity. We also use or may use these technologies to help us determine and identify repeat visitors, the type of content and sites to which a user of our websites links, the length of time each user spends at any particular area of our websites, and the specific functionalities that users choose to use.`
                      : `Cuando utiliza nuestros servicios, nosotros y nuestros proveedores de servicios (que son empresas de terceros que trabajan en nuestro nombre) podemos utilizar cookies y tecnologías similares para rastrear, recopilar y agregar cierta información, incluyendo, entre otras cosas, qué páginas de nuestros sitios web fueron visitadas, el orden en que fueron visitadas, cuándo fueron visitadas, las URL desde las que se vinculó a nuestros sitios web y en qué hipervínculos se hizo clic. La recopilación de dicha información puede implicar el registro de la dirección IP, el sistema operativo y el software del navegador utilizado por cada usuario del sitio web. A través de dicha información, podemos determinar a partir de una dirección IP el proveedor de servicios de Internet de un usuario y la ubicación geográfica general (ciudad/estado) de su punto de conectividad. También utilizamos o podemos utilizar estas tecnologías para ayudarnos a determinar e identificar visitantes recurrentes, el tipo de contenido y sitios a los que se vincula un usuario de nuestros sitios web, el tiempo que cada usuario pasa en un área particular de nuestros sitios web y las funcionalidades específicas que los usuarios eligen utilizar.`}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang == "en"
                      ? `We use third-party web analytics services (such as those of Google Analytics) on our website to collect and analyze usage information through cookies and similar tools; engage in auditing, researching, or reporting; assist with fraud prevention; and provide certain features to you. We also provide a limited amount of your information (such as your e-mail address and order history) to our marketing agency partners so that they can analyze website usage on our behalf, which allows us to improve our services.`
                      : `Utilizamos servicios de análisis web de terceros (como los de Google Analytics) en nuestro sitio web para recopilar y analizar información de uso a través de cookies y herramientas similares; realizar auditorías, investigaciones o informes; ayudar con la prevención del fraude; y brindarle ciertas funciones. También proporcionamos una cantidad limitada de su información (como su dirección de correo electrónico e historial de pedidos) a nuestros socios de agencias de marketing para que puedan analizar el uso del sitio web en nuestro nombre, lo que nos permite mejorar nuestros servicios.`}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `For more information about our use of cookies and similar technologies, including how you can install the Google Analytics Opt-out Browser Add-on to prevent Google from using your information for analytics, or otherwise control or disable cookies, please see`
                      : "Para obtener más información sobre nuestro uso de cookies y tecnologías similares, incluido cómo puede instalar el complemento de inhabilitación para navegadores de Google Analytics para evitar que Google use su información para análisis o controlar o deshabilitar las cookies, consulte"}
                    <a href="https://tools.google.com/dlpage/gaoptout">
                      {" "}
                      https://tools.google.com/dlpage/gaoptout.
                    </a>
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? "WHAT WE DO WITH THE INFORMATION WE GATHER"
                        : "QUÉ HACEMOS CON LA INFORMACIÓN QUE RECOPILAMOS"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:`
                      : "Necesitamos esta información para entender sus necesidades y brindarle un mejor servicio, y en particular por las siguientes razones:"}
                  </span>
                </p>
                <ul>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Send you information in connection with the media, information, and services offered`
                          : "Enviarle información en relación con los medios, información y servicios ofrecidos"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Provide the content and services you have requested, pursuant to our Terms and Conditions`
                          : "Proporcionar el contenido y los servicios que ha solicitado, de conformidad con nuestros Términos y Condiciones"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Respond to your communications and requests`
                          : "Responder a sus comunicaciones y solicitudes"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Contact you by e-mail, postal mail, or phone regarding the Company content, services, content, surveys, research studies, promotions, and other subjects that we think may be of interest to you`
                          : "Comunicarnos con usted por correo electrónico, correo postal o teléfono en relación con el contenido de la Compañía, servicios, contenidos, encuestas, estudios de investigación, promociones y otros temas que consideramos que pueden ser de su interés"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `To confirm or fulfill a request that you make (e.g. in order to fulfill an order, we will share your contact information with our third party providers)`
                          : "Para confirmar o cumplir con una solicitud que usted realice (por ejemplo, para cumplir con un pedido, compartiremos su información de contacto con nuestros proveedores externos)"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Customize the content we provide through our media, information, and services`
                          : "Personalizar el contenido que ofrecemos a través de nuestros medios, información y servicios"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `To provide you with notices about your account and subscriptions, including expiration and renewal notices`
                          : "Para proporcionarle avisos sobre su cuenta y suscripciones, incluidos avisos de vencimiento y renovación"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `To manage relationships with partners, advertisers, or other third parties to which the website may disclose personally identifiable information pursuant to this policy`
                          : "Para gestionar las relaciones con socios, anunciantes u otros terceros a los que el sitio web pueda divulgar información de identificación personal de conformidad con esta política"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Help us better understand your interests and needs, and improve our media, content, information, and services`
                          : "Ayúdenos a comprender mejor sus intereses y necesidades, y a mejorar nuestros medios, contenidos, información y servicios."}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Comply with any procedures, laws, and regulations which apply to us where it is necessary for our legitimate interests or the legitimate interests of others`
                          : "Cumplir con todos los procedimientos, leyes y regulaciones que se nos apliquen cuando sea necesario para nuestros intereses legítimos o los intereses legítimos de otros"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Establish, exercise, or defend our legal rights where it is necessary for our legitimate interests or the legitimate interests of others`
                          : "Establecer, ejercer o defender nuestros derechos legales cuando sea necesario para nuestros intereses legítimos o los intereses legítimos de otros"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Internal record keeping`
                          : "Mantenimiento de registros internos"}
                      </span>
                    </p>
                  </li>
                </ul>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `The website may share personally identifiable information that you provide with third parties in the following cases:`
                      : "El sitio web podrá compartir información de identificación personal que usted proporcione con terceros en los siguientes casos:"}
                  </span>
                </p>
                <ul>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `The website maintains relationships with third party providers for some of the products and services offered through the website. We share your information with authorized service providers involved in operating the Service. Our authorized service providers also include payment providers, email service providers, and analytics companies.`
                          : "El sitio web mantiene relaciones con proveedores externos para algunos de los productos y servicios que se ofrecen a través del sitio web. Compartimos su información con proveedores de servicios autorizados que participan en la operación del Servicio. Nuestros proveedores de servicios autorizados también incluyen proveedores de pago, proveedores de servicios de correo electrónico y empresas de análisis."}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `If you request information or services through the website, you agree that the website may share personally identifiable information and usage data as may be necessary to provide such information to, or perform such services for, you. In some cases, the information that you provide through the website may be collected directly by a third party or its affiliates. Personally identifiable information also may be shared with the specific third parties that can respond to your requests in the form of reporting and other follow-up communications. The subsequent use or disclosure of any information collected by or provided to third parties is subject to their privacy policies and practices.`
                          : "Si solicita información o servicios a través del sitio web, acepta que el sitio web puede compartir información de identificación personal y datos de uso según sea necesario para proporcionarle dicha información o realizarle dichos servicios. En algunos casos, la información que usted proporciona a través del sitio web puede ser recopilada directamente por un tercero o sus afiliados. La información de identificación personal también puede compartirse con terceros específicos que pueden responder a sus solicitudes en forma de informes y otras comunicaciones de seguimiento. El uso o la divulgación posterior de cualquier información recopilada o proporcionada a terceros está sujeta a sus políticas y prácticas de privacidad."}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `As provided in this policy, and if you are 18 years of age or older and have agreed to receive third party direct marketing materials, the website may share your personally identifiable information with third parties for direct marketing purposes. Although we will endeavor to notify all third-party direct marketers that the information provided to them is confidential, the subsequent use or disclosure of information is subject to those third parties’ privacy policies and practices.`
                          : "Según lo dispuesto en esta política, y si tiene 18 años o más y ha aceptado recibir materiales de marketing directo de terceros, el sitio web puede compartir su información de identificación personal con terceros para fines de marketing directo. Si bien nos esforzaremos por notificar a todos los vendedores directos externos que la información que se les proporciona es confidencial, el uso o la divulgación posterior de la información está sujeto a las políticas y prácticas de privacidad de dichos terceros."}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `If you make any personally identifiable information publicly available on the website (e.g., by posting a comment on a video which contains personally identifiable information), anyone may see and use such information and we are not responsible for such use. The Company reserves the right to share any personally identifiable information you post on the website with third parties with your prior consent. To the extent the website does so, the subsequent use and disclosure of such information is subject to the privacy policies and practices of those third parties.`
                          : "Si usted publica información personal identificable en el sitio web (por ejemplo, al publicar un comentario en un video que contiene información personal identificable), cualquier persona podrá ver y usar dicha información y no seremos responsables de dicho uso. La Compañía se reserva el derecho de compartir con terceros cualquier información personal identificable que usted publique en el sitio web con su consentimiento previo. En la medida en que el sitio web lo haga, el uso y la divulgación posteriores de dicha información estarán sujetos a las políticas y prácticas de privacidad de dichos terceros."}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Internal record keeping`
                          : "Mantenimiento de registros internos"}
                      </span>
                    </p>
                  </li>
                </ul>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>TARGETED ADVERTISING</b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `We may use your personal information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. You can limit the use of your information for purposes of targeted advertising using a number of methods:`
                      : "Podemos utilizar su información personal para ofrecerle anuncios publicitarios o comunicaciones de marketing personalizados que consideremos que pueden ser de su interés. Puede limitar el uso de su información para fines de publicidad personalizada utilizando varios métodos:"}
                  </span>
                </p>
                <ul>
                  <li>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: "medium",
                      }}
                    >
                      {lang === "en"
                        ? `Via the Digital Advertising Alliance’s tool available at `
                        : "A través de la herramienta de la Alianza de Publicidad Digital disponible en "}
                      <a
                        href="https://optout.aboutads.info/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        optout.aboutads.info
                      </a>
                      {lang === "en"
                        ? `, which lets you opt-out of interest-based ads on websites.`
                        : ", que le permite optar por no recibir anuncios basados ​​en intereses en sitios web."}
                    </span>
                  </li>
                  <li>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: "medium",
                      }}
                    >
                      {lang === "en"
                        ? `Via AppChoices mobile app, available at `
                        : "A través de la aplicación móvil AppChoices, disponible en "}
                      <a
                        href="https://www.youradchoices.com/appchoices"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.youradchoices.com/appchoices
                      </a>
                      {lang === "en"
                        ? `, which lets you opt-out of interest-based ads in mobile apps.`
                        : ", que le permite optar por no recibir anuncios basados ​​en intereses en aplicaciones móviles."}
                    </span>
                  </li>
                  <li>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: "medium",
                      }}
                    >
                      {lang === "en"
                        ? `Using platform specific opt-out features:`
                        : "Uso de funciones de exclusión específicas de la plataforma:"}
                    </span>
                  </li>
                <ul>
                  <li>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: "medium",
                      }}
                    >
                      <span lang="nl-NL">
                        Google:{" "}
                        <a
                          href="https://adssettings.google.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://adssettings.google.com/
                        </a>
                      </span>
                    </span>
                  </li>
                  <li>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: "medium",
                      }}
                    >
                      Facebook:{" "}
                      <a
                        href="https://www.facebook.com/about/ads"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.facebook.com/about/ads
                      </a>
                    </span>
                  </li>
                </ul>
                <li>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `By configuring your browser settings and/or mobile settings to restrict third-party cookies and/or the use of the advertising ID associated with your mobile device for interest-based advertising purposes. `
                      : "Configurando la configuración de su navegador y/o la configuración móvil para restringir las cookies de terceros y/o el uso del ID de publicidad asociado a su dispositivo móvil para fines publicitarios basados ​​en intereses."}
                  </span>
                </li>
                </ul>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `The options described above must be set on each of your devices in order to apply. Not all companies that serve interest-based ads participate in the ad industry opt-out programs described above, so even after opting-out, you may still receive some cookies and interest-based ads from other companies.`
                      : "Las opciones descritas anteriormente deben estar configuradas en cada uno de sus dispositivos para que se apliquen. No todas las empresas que ofrecen anuncios basados ​​en intereses participan en los programas de exclusión voluntaria de la industria publicitaria descritos anteriormente, por lo que incluso después de optar por no participar, es posible que aún reciba algunas cookies y anuncios basados ​​en intereses de otras empresas."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? "AGGREGATE / ANONYMOUS DATA"
                        : "DATOS AGREGADOS/ANÓNIMOS"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `We may aggregate and/or anonymize any information collected through the website so that such information can no longer be linked to you or your device (“Aggregate/Anonymous Information”). We may use Aggregate/Anonymous Information for any purpose, including without limitation for research and marketing purposes, statistical analyses of website(s) usage, and may also share such data with any third parties, including advertisers, promotional partners, and sponsors.`
                      : "Podemos agregar y/o anonimizar cualquier información recopilada a través del sitio web para que dicha información ya no pueda vincularse con usted o su dispositivo (\"Información agregada/anónima\"). Podemos utilizar la Información agregada/anónima para cualquier propósito, incluidos, entre otros, fines de investigación y marketing, análisis estadísticos del uso del sitio web y también podemos compartir dichos datos con terceros, incluidos anunciantes, socios promocionales y patrocinadores."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? "SOCIAL MEDIA LINKS AND FEATURES"
                        : "ENLACES Y FUNCIONES DE REDES SOCIALES"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `Our websites may contain links to third-party social media sites, and also contain third-party integrations (such as Instagram, LinkedIn, or the Facebook “Like” button) and functionalities. If you choose to use these sites or features, you may disclose your information not just to those third-parties, but also to their users and the public more generally depending on how their services function. The Company is not responsible for the content or practices of those websites or services. The collection, use, and disclosure of your information will be subject to the privacy policies of the third-party websites or services, and we urge you to read the privacy and security policies of those third-parties.`
                      : `Nuestros sitios web pueden contener enlaces a sitios de redes sociales de terceros y también pueden contener integraciones y funcionalidades de terceros (como Instagram, LinkedIn o el botón “Me gusta” de Facebook). Si elige utilizar estos sitios o funciones, puede divulgar su información no solo a esos terceros, sino también a sus usuarios y al público en general, según cómo funcionen sus servicios. La Compañía no es responsable del contenido o las prácticas de esos sitios web o servicios. La recopilación, el uso y la divulgación de su información estarán sujetos a las políticas de privacidad de los sitios web o servicios de terceros, y le recomendamos que lea las políticas de privacidad y seguridad de esos terceros.`}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? "DATA SUBJECT RIGHTS AND YOUR CHOICES"
                        : "DERECHOS DEL TITULAR DE LOS DATOS Y SUS OPCIONES"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `You may have certain rights with respect to your information as further described in this section. If you are a resident of California or Nevada, you may have additional rights with respect to your information. In addition, your local laws may permit you to request that we:`
                      : "Es posible que tenga ciertos derechos con respecto a su información, como se describe con más detalle en esta sección. Si es residente de California o Nevada, es posible que tenga derechos adicionales con respecto a su información. Además, sus leyes locales pueden permitirle solicitar que:"}
                  </span>
                </p>
                <ul>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Provide access to and/or a copy of certain information we hold about you`
                          : "Proporcionar acceso y/o una copia de cierta información que tenemos sobre usted"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Prevent the processing of your information for direct-marketing purposes (including any direct marketing processing based on profiling)`
                          : `Impedir el procesamiento de su información con fines de marketing directo (incluido cualquier procesamiento de marketing directo basado en perfiles)`}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Update information which is out of date or incorrect`
                          : "Actualizar información que esté desactualizada o incorrecta"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Delete certain information which we are holding about you`
                          : "Eliminar cierta información que tenemos sobre usted"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Restrict the way that we process and disclose certain of your information`
                          : "Restringir la forma en que procesamos y divulgamos cierta información suya"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Transfer your information to a third-party provider of services`
                          : "Transferir su información a un proveedor externo de servicios"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: "medium",
                        }}
                      >
                        {lang === "en"
                          ? `Revoke your consent for the processing of your information`
                          : "Revocar su consentimiento para el tratamiento de su información"}
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? `Users from the European Economic Area (EEA) have the right to:`
                        : "Los usuarios del Espacio Económico Europeo (EEE) tienen derecho a:"}
                    </b>
                    {lang === "en"
                      ? ` opt out of cookies (see our Cookie Policy); access, correct, delete, restrict or object to our use of your personal information.  If you would like to exercise any of these rights, please submit your request by using our form. We process personal information to fulfill contracts with you and to provide services you have requested (for example, to fulfill a purchase), or otherwise to pursue our legitimate business interests.  Note that your information will be transferred outside of Europe, including to the United States.`
                      : "optar por no recibir cookies (consulte nuestra Política de cookies); acceder, corregir, eliminar, restringir u oponerse a nuestro uso de su información personal. Si desea ejercer alguno de estos derechos, envíe su solicitud mediante nuestro formulario. Procesamos información personal para cumplir contratos con usted y para proporcionar los servicios que ha solicitado (por ejemplo, para completar una compra) o, de lo contrario, para perseguir nuestros intereses comerciales legítimos. Tenga en cuenta que su información se transferirá fuera de Europa, incluso a los Estados Unidos."}
                    </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `We will consider all requests and provide our response within the time period stated by applicable law. Please note, however, that certain information may be exempt from such requests in some circumstances, which may include if we need to keep processing your information for our legitimate interests, to comply with a legal obligation, or if necessary for the performance of a contract involving you or us. To the extent that you make such a request, we require certain information to help verify your request and prevent fraudulent information and removal requests. Specifically, we ask that you provide the following pieces of information:`
                      : "Consideraremos todas las solicitudes y le daremos nuestra respuesta dentro del plazo establecido por la ley aplicable. Sin embargo, tenga en cuenta que cierta información puede estar exenta de dichas solicitudes en algunas circunstancias, que pueden incluir si necesitamos seguir procesando su información para nuestros intereses legítimos, para cumplir con una obligación legal o si es necesario para la ejecución de un contrato que lo involucra a usted o a nosotros. En la medida en que realice una solicitud de este tipo, necesitaremos cierta información para ayudar a verificar su solicitud y evitar información fraudulenta y solicitudes de eliminación. En concreto, le solicitamos que proporcione la siguiente información:"}
                  </span>
                </p>
                <ol>
                  <li>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: "medium",
                      }}
                    >
                      {lang === "en"
                        ? `Username and e-mail address associated with your user account(s)`
                        : "Nombre de usuario y dirección de correo electrónico asociada a su(s) cuenta(s) de usuario"}
                    </span>
                  </li>
                  <li>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: "medium",
                      }}
                    >
                      {lang === "en"
                        ? `Approximate date of user account registration`
                        : "Fecha aproximada de registro de la cuenta de usuario."}
                    </span>
                  </li>
                  <li>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: "medium",
                      }}
                    >
                      {lang === "en"
                        ? `Information you want to view and/or request removed`
                        : "Información que desea ver y/o eliminar."}
                    </span>
                  </li>
                  <li>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: "medium",
                      }}
                    >
                      {lang === "en"
                        ? `A statement under penalty of perjury that all information in your request is truthful and that this is your user account or that you have the authorization to make the request on behalf of the owner of the user account`
                        : "Una declaración bajo pena de perjurio de que toda la información en su solicitud es veraz y que esta es su cuenta de usuario o que tiene la autorización para realizar la solicitud en nombre del propietario de la cuenta de usuario"}
                    </span>
                  </li>
                </ol>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `Depending on the circumstances and the information you request, we may require additional methods of verification in our discretion.`
                      : "Dependiendo de las circunstancias y la información que solicite, es posible que requieramos métodos de verificación adicionales a nuestra discreción."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {" "}
                      {lang === "en"
                        ? `MARKETING COMMUNICATIONS`
                        : "Comunicaciones de Marketing"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `You may instruct us not to use your contact information to contact you by e-mail, postal mail, or phone regarding services, promotions and special content that might appeal to your interests by contacting us using the information below. In commercial e-mail messages, you can also opt out by following the instructions located at the bottom of such e-mails. Please note that, regardless of your request, we may still use and share certain information as permitted by this privacy policy or as required by applicable law. For example, you may not opt out of certain operational e-mails, such as those reflecting our relationship or transactions with you.`
                      : "Puede indicarnos que no utilicemos su información de contacto para comunicarnos con usted por correo electrónico, correo postal o teléfono en relación con servicios, promociones y contenido especial que puedan interesarle, comunicándose con nosotros mediante la información que se encuentra a continuación. En el caso de los mensajes de correo electrónico comerciales, también puede optar por no recibirlos siguiendo las instrucciones que se encuentran en la parte inferior de dichos mensajes. Tenga en cuenta que, independientemente de su solicitud, aún podemos usar y compartir cierta información según lo permita esta política de privacidad o según lo exija la ley aplicable. Por ejemplo, no puede optar por no recibir ciertos correos electrónicos operativos, como los que reflejan nuestra relación o transacciones con usted."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? `JURISDICTION AND CROSS-BORDER TRANSFER`
                        : "JURISDICCIÓN Y TRANSFERENCIA TRANSFRONTERIZA"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `Your personally identifiable information may be stored and processed in any country, including the United States, where we have facilities in which we engage third-party service providers, and by requesting the Company content and services you consent to the transfer of information to countries outside of your country of residence which may have data protection rules that are different from those of your country. In certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in those other countries may be entitled to access your personally identifiable information.`
                      : "Su información personal identificable puede almacenarse y procesarse en cualquier país, incluido Estados Unidos, donde tengamos instalaciones en las que contratemos a proveedores de servicios externos, y al solicitar contenido y servicios de la Compañía, usted da su consentimiento para la transferencia de información a países fuera de su país de residencia que pueden tener reglas de protección de datos diferentes a las de su país. En determinadas circunstancias, los tribunales, las agencias de aplicación de la ley, las agencias reguladoras o las autoridades de seguridad de esos otros países pueden tener derecho a acceder a su información personal identificable."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en" ? `HOW WE PROTECT YOUR INFORMATION` : "CÓMO PROTEGEMOS SU INFORMACIÓN"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `We consider the confidentiality and security of your information to be of the utmost importance. The Company takes a variety of technical, administrative, and physical measures to protect your information from disclosure to or access by third parties. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. Please note that we do not guarantee the security of information, as no method of internet transmission or storage is completely secure. Also note that we may retain and store your information in our databases and systems even after your account is deactivated as long as we have a legitimate purpose to do so and in accordance with applicable law. We intend to take reasonable and appropriate steps to protect the information that you share with us or third-party service providers from unauthorized access or disclosure. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us by e-mail at support@gigmiapp.com.`
                      : "Consideramos que la confidencialidad y seguridad de su información son de suma importancia. La Compañía toma una variedad de medidas técnicas, administrativas y físicas para proteger su información de la divulgación o acceso por parte de terceros. Con el fin de evitar el acceso o la divulgación no autorizados, hemos puesto en marcha procedimientos físicos, electrónicos y de gestión adecuados para salvaguardar y asegurar la información que recopilamos en línea. Seguimos los estándares de la industria generalmente aceptados para proteger la información personal que se nos envía, tanto durante la transmisión como una vez que la recibimos. Tenga en cuenta que no garantizamos la seguridad de la información, ya que ningún método de transmisión o almacenamiento por Internet es completamente seguro. Tenga en cuenta también que podemos retener y almacenar su información en nuestras bases de datos y sistemas incluso después de que se desactive su cuenta, siempre que tengamos un propósito legítimo para hacerlo y de acuerdo con la ley aplicable. Tenemos la intención de tomar medidas razonables y apropiadas para proteger la información que comparte con nosotros o con proveedores de servicios externos del acceso o la divulgación no autorizados. Si tiene motivos para creer que su interacción con nosotros ya no es segura, notifíquenos de inmediato por correo electrónico a support@gigmiapp.com."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? `CONTROLLING YOUR PERSONAL INFORMATION`
                        : "CONTROLANDO SU INFORMACIÓN PERSONAL"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `You may choose to restrict the collection or use of your personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or e-mailing us at support@gigmiapp.com.`
                      : "Puede optar por restringir la recopilación o el uso de su información personal. Si previamente aceptó que usemos su información personal con fines de marketing directo, puede cambiar de opinión en cualquier momento escribiéndonos o enviándonos un correo electrónico a support@gigmiapp.com."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `We also send our users site, content, and service announcement updates. Users are not able to un-subscribe from service announcements, which contain important information about service notices and responsibilities you have signed up for. The Company occasionally communicates with its users via email and phone to provide information about requested services and regarding issues related to their account. It is important to note that this communication is not used for marketing or promotional purposes. Requesting the deletion of certain types of user information may prevent users from accessing some website features.`
                      : "También enviamos a nuestros usuarios actualizaciones sobre el sitio, el contenido y los anuncios de servicios. Los usuarios no pueden cancelar su suscripción a los anuncios de servicios, que contienen información importante sobre los avisos de servicio y las responsabilidades a las que se han suscrito. La Compañía se comunica ocasionalmente con sus usuarios por correo electrónico y teléfono para brindarles información sobre los servicios solicitados y sobre cuestiones relacionadas con su cuenta. Es importante tener en cuenta que esta comunicación no se utiliza con fines de marketing o promoción. Solicitar la eliminación de ciertos tipos de información de los usuarios puede impedir que estos accedan a algunas funciones del sitio web."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting. You will have the choice to opt out from receiving this promotional information.`
                      : "No venderemos, distribuiremos ni cederemos su información personal a terceros a menos que tengamos su permiso o estemos obligados por ley a hacerlo. Podemos utilizar su información personal para enviarle información promocional sobre terceros que creemos que puede resultarle interesante. Usted tendrá la opción de optar por no recibir esta información promocional."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? `CHANGING, UPDATING, OR DELETING PERSONAL INFORMATION`
                        : "Cambiar, actualizar o eliminar información personal"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `If you believe that any information we are holding on you is incorrect or incomplete, please write to or e-mail as soon as possible. We will promptly correct any information found to be incorrect.`
                      : "Si cree que algún dato que tenemos sobre usted es incorrecto o está incompleto, escríbanos o envíenos un correo electrónico lo antes posible. Corregiremos de inmediato cualquier información que sea incorrecta."}
                  </span>
                </p>

                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `If you would like to request to review, correct, update, suppress, restrict, or delete personally identifiable information that you have previously provided to us, or if you would like to request to receive an electronic copy of your personally identifiable information for purposes of transmitting it to another company (to the extent this right to data portability is provided to you by applicable law), you may contact us by e-mail at support@gigmiapp.com. We will respond to your request consistent with applicable law.`
                      : "Si desea solicitar revisar, corregir, actualizar, suprimir, restringir o eliminar información de identificación personal que nos haya proporcionado previamente, o si desea solicitar recibir una copia electrónica de su información de identificación personal con el fin de transmitirla a otra empresa (en la medida en que la ley aplicable le otorgue este derecho a la portabilidad de datos), puede comunicarse con nosotros por correo electrónico a support@gigmiapp.com. Responderemos a su solicitud de conformidad con la ley aplicable."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `In your request, please make clear what personally identifiable information you would like to have changed, whether you would like to have your personally identifiable information suppressed from our database or otherwise let us know what limitations you would like to put on our use of your personally identifiable information. For your protection, we will only implement requests with respect to the personally identifiable information associated with the particular e-mail address that you use to send us your request, and we may need to verify your identity before implementing your request. We will try to comply with your request as soon as reasonably practicable.`
                      : "En su solicitud, indique claramente qué información personal identificable desea que se modifique, si desea que se elimine dicha información de nuestra base de datos o, de lo contrario, infórmenos qué limitaciones desea imponer a nuestro uso de dicha información. Para su protección, solo implementaremos solicitudes con respecto a la información personal identificable asociada con la dirección de correo electrónico particular que utilice para enviarnos su solicitud, y es posible que necesitemos verificar su identidad antes de implementar su solicitud. Intentaremos cumplir con su solicitud lo antes posible."}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting a change or deletion. There may also be residual information that will remain within our databases and other records, which will not be removed.`
                      : "Tenga en cuenta que es posible que necesitemos conservar cierta información para fines de mantenimiento de registros y/o para completar cualquier transacción que haya iniciado antes de solicitar un cambio o eliminación. También puede haber información residual que permanecerá en nuestras bases de datos y otros registros, que no se eliminará."}
                  </span>
                </p>
                <p></p>

                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? `DELETING USER ACCOUNT`
                        : "Cambiar, actualizar o eliminar información personal"}
                    </b>
                  </span>
                </p>

                <p>
                  <span
                    id="delete-account"
                    ref={deleteAccountRef}
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en" ? (
                      <>
                        If you want to delete your account, you can do so by
                        following these steps:
                        <ul>
                          <li>Log in to your account.</li>
                          <li>Go to the "Settings" tab.</li>
                          <li>
                            In the Settings tab, you will find a "Delete
                            Account" button. Click on it to proceed with
                            deleting your account.
                          </li>
                        </ul>
                      </>
                    ) : (
                      <>
                        Si desea eliminar su cuenta, puede hacerlo siguiendo
                        estos pasos:
                        <ul>
                          <li>Inicie sesión en su cuenta.</li>
                          <li>Vaya a la pestaña "Configuración".</li>
                          <li>
                            En la pestaña Configuración, encontrará un botón
                            "Eliminar cuenta". Haga clic en él para proceder con
                            la eliminación de su cuenta.
                          </li>
                        </ul>
                      </>
                    )}
                  </span>
                </p>

                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? `CHANGES TO OUR PRIVACY POLICY`
                        : "CAMBIOS A NUESTRA POLÍTICA DE PRIVACIDAD"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `Please note that we review our privacy practices from time to time, and that these practices are subject to change. Any change, update, or modification will be effective immediately upon posting on our websites. You can know if the Privacy Policy has changed since the last time you reviewed it by checking the “Effective Date” included at the beginning of the document. If we make a material change to the Policy, you will be provided with appropriate notice in accordance with legal requirements. By continuing to use our services, you are confirming that you have read and understood the latest version of this Privacy Policy. Be sure to return to this page periodically to ensure familiarity with the most current version of this Privacy Policy.`
                      : `Tenga en cuenta que revisamos nuestras prácticas de privacidad de vez en cuando y que estas prácticas están sujetas a cambios. Cualquier cambio, actualización o modificación entrará en vigencia inmediatamente después de su publicación en nuestros sitios web. Puede saber si la Política de privacidad ha cambiado desde la última vez que la revisó consultando la “Fecha de entrada en vigencia” incluida al comienzo del documento. Si realizamos un cambio material en la Política, se le proporcionará un aviso apropiado de acuerdo con los requisitos legales. Al continuar utilizando nuestros servicios, confirma que ha leído y comprendido la última versión de esta Política de privacidad. Asegúrese de volver a esta página periódicamente para asegurarse de estar familiarizado con la versión más actualizada de esta Política de privacidad.`}
                  </span>
                </p>
                <p></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <b>
                      {lang === "en"
                        ? `THE COMPANY CONTACT INFORMATION`
                        : "INFORMACIÓN DE CONTACTO DE LA EMPRESA"}
                    </b>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    {lang === "en"
                      ? `If you have any questions, comments or complaints about this Privacy Policy, please contact us:`
                      : "Si tiene alguna pregunta, comentario o queja sobre esta Política de privacidad, contáctenos:"}
                  </span>
                  <br />
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <span lang="it-IT">E-mail: </span>
                    <span lang="it-IT">support@gigmiapp.com</span>
                    <span lang="it-IT">.</span>
                  </span>
                </p>
                <p lang="it-IT"></p>
                <p>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    <span lang="it-IT">GigMi LLC</span>
                  </span>
                  <br />
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "medium",
                    }}
                  >
                    911 N. Franklin Avenue
                    <br />
                    Loveland, CO 80537
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
